<!-- <h1 mat-dialog-title>Hi</h1> -->
<div mat-dialog-content>
  <!-- <p>What's your favorite animal?</p> -->
  <div class="px-5 pt-4">
    <section class="text-9xl text-center">
      <mat-icon [inline]="true" class="ro-font-yellow">error</mat-icon>
    </section>
    <div class="text-2xl text-center font-light uppercase mb-2 ro-font-yellow">
      Advertencia
    </div>
    <div class="text-base text-center font-light mb-3 ro-font-grey">
        {{ data.description }}
    </div>
  </div>
  
</div>
<hr>
<div
  class="w-full h-full text-center py-3 text-lg flex justify-around"
>
  <div class="cursor-pointer" (click)="cancel()">
    Cancelar 
  </div>
  <div class="cursor-pointer" (click)="on()">
    Aceptar 
  </div>
</div>
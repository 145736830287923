import { createSelector } from '@ngrx/store';
import { initialStateProfile, ProfileModal } from './profile.model';

export namespace ProfileState{
    export interface IState {
        data: any;
        success: boolean;
        loading: boolean
    }

    export const initialState: ProfileModal = {
        data: initialStateProfile,
        success: false,
        loading: false
    }
}

export const profileData = createSelector(
    (state: any) => state.profile,
    (state: any) => state
);

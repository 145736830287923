import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';;
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(
    private http: HttpClient,
  ) { }

  public setPropery() {

  }
  public searchProperties(body) {
    return this.http.post<any>(`${environment.uri}/lastproperties`, body)
      .pipe(
        map( res => res.data )
      );
  }
  // get last properties
  public getLastProperties(){
    return this.http.get<any>(`${environment.uri}/lastproperties`)
      .pipe(
        map( res => res.data )
      );
  }
  public getMe(){
    return this.http.get<any>(`${environment.uri}/finduser`)
      .pipe(
        map( res => res.data )
      );
  }

  public getProperty() {
    return this.http.get<any>(`${environment.uri}`)
      .pipe(
        map( res => res.data )
      );
  }

  public getAllTypeByProperties() {
    return this.http.get<any>(`${environment.uri}/getalltypeproperty`)
      .pipe(
        map( res => res.data )
      );
  }
  
}

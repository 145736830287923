


    <button
        mat-button
        class="button"
        [ngStyle]="ngStyle"
        [color]="color"
        [disabled]="disabled"
    >
        <ng-content></ng-content>
    </button>
        

    <!-- <button
        mat-raised-button
        class="button"
        [ngStyle]="ngStyle"
        [color]="color"
        [disabled]="disabled"
    >
        <ng-content select="#raised"></ng-content>
    </button> -->
    <!-- </some-element>
    <some-element *ngSwitchCase="'flat'">
        <button
            mat-flat-button
            class="button"
            [ngStyle]="ngStyle"
            [color]="color"
            [disabled]="disabled"
        >
            <ng-content></ng-content>
        </button>
    </some-element>
    <some-element *ngSwitchCase="'stroked'">
        <button
            mat-stroked-button
            class="button"
            [ngStyle]="ngStyle"
            [color]="color"
            [disabled]="disabled"
        >
            <ng-content></ng-content>
        </button>
    </some-element>
    <some-element *ngSwitchDefault>
        <button
            mat-stroked-button
            class="button"
            [ngStyle]="ngStyle"
            [color]="color"
            [disabled]="disabled"
        >
            <ng-content></ng-content>
        </button>
    </some-element>
</container-element> -->


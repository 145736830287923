<!-- <h1 mat-dialog-title>Hi</h1> -->
<div mat-dialog-content>
  <!-- <p>What's your favorite animal?</p> -->
  <div class="px-5 pt-4">
    <section class="text-9xl text-center">
      <mat-icon [inline]="true" [ngClass]="textIcon.color">{{ textIcon.key }}</mat-icon>
    </section>
    <div class="text-2xl text-center font-light uppercase mb-2" [ngClass]="textIcon.color">
      {{ textIcon.title }}
    </div>
    <div class="text-base text-center font-light mb-3 ro-font-grey">
        {{ data.description }}
    </div>
  </div>
  
</div>
<hr>
<div
  (click)="onNoClick()"
  class="w-full h-full text-center cursor-pointer py-3 text-lg"
>
  Confirmar
</div>
<!-- <div mat-dialog-actions class="flex justify-center">
  <ro-button-stroked
      color = 'primary'
      (click)="onNoClick()"
  >   
      <div class="text-lg p-1">
        {{ data.buttons[0] }}
      </div>
  </ro-button-stroked>
  <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
</div> -->
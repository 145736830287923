export interface ProfileModal {
    data: profileInterface;
    success: boolean;
    loading: boolean;
}

export interface interestInterface {
    key: string;
    active: number;
}

export interface profileInterface {
    ocupation: number,
    description: string,
    age: number,
    interests: Array<interestInterface>
}

export const initialStateProfile: profileInterface = {
    ocupation: 1,
    description: "",
    age: 0, 
    interests: [],
}
import { createSelector } from '@ngrx/store';
import { initialStateHome, HomenModal, HomeInterface } from './home.model';

export namespace HomeState{
    export interface IState {
        data: HomeInterface;
        success: boolean;
        loading: boolean
    }

    export const initialState: HomenModal = {
        data: initialStateHome,
        success: false,
        loading: false
    }
}

export const homeData = createSelector(
    (state: any) => state.home,
    (state: any) => state
);

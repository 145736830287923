export const URI_S3 = 'https://bucket-roomout.s3.us-east-2.amazonaws.com/';
export const BUCCKET_PROFILE = 'imageProfile/';
export const IMAGE_DEFAULT = '../../../assets/img/usuario-de-perfil.png';

export const ROUTES = {
    raiz: '/',
    profile: '/profile',
    post: '/post',
    myPost: '/post/mis-publicaciones',
}

export const SESSION_STORAGE = {
    token: 'token',
    user: 'user',
}
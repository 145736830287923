export interface HomenModal {
    data: HomeInterface;
    success: boolean;
    loading: boolean;
}

export interface HomeInterface {
    email: string;
    creationprofile: boolean
    name: string;
    lastname: string;
    type: string;
    img: string;
}

export const initialStateHome: HomeInterface = {
    email: "",
    creationprofile: false,
    name: "",
    lastname: "",
    type: "",
    img: ""
}
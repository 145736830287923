import { HomeAction } from './home.actions';
import { HomenModal } from './home.model';
import { HomeState } from './home.state';

export function HomeReducer(
    state = HomeState.initialState,
    action: HomeAction.Actions
): HomenModal{
    switch (action.type) {
        case HomeAction.Types.LOGUIN_SUCCESS: {
            return {
                ...state
            }
        }

        case HomeAction.Types.LOGUIN_REQUEST :{
            const data = action.payload;
            return {
                ...state,
                data,
            }
        }
        default: {
            return state;
        }
    }
}

<section class="header">
    <section class="hidden lg:flex flex-col lg:flex-row lg:justify-between font-bold text-lg container mx-auto" > 
        <section class="header__logo header__border-bottom grid grid-flow-col grid-rows-1 lg:grid-rows-1 place-items-center ">
            <div class="p-3" (click)="logo()">
                <div class="header__logo-icon text-3xl ro-bg-purple ro-font-white grid place-content-center cursor-pointer">
                    <div>R</div>
                </div>
            </div>
        </section>
        <section class="header__options grid lg:grid-flow-col grid-rows-2 lg:grid-rows-1 place-items-center ">
            <div class="p-3 header__border-bottom w-100" *ngIf="!isLogged">
                <ro-button-flat
                    color = 'primary'
                    routerLink="/auth"
                >   
                    <div class="text-base px-2 py-2 font-medium">
                        Inciar sesión
                    </div>
                </ro-button-flat>
            </div>
            <div class="p-3" *ngIf="!isLogged">
                <ro-button-flat
                    color="accent"
                    routerLink="/auth/register"
                >
                    <div class="text-base px-2 py-2 font-medium">
                        Registrarse
                    </div>
                    
                </ro-button-flat>
            </div>
            <div class="px-6 ">
                <div 
                    class="flex items-center cursor-pointer" 
                    [matMenuTriggerFor]="menu" 
                    (menuOpened)="openMenu()" 
                    (menuClosed)="closeMenu()"
                    *ngIf="isLogged"
                >
                    <figure class="mr-2">
                        <img 
                            class="header__imgUser" 
                            [src]='loadPhotoProfile()' >
                    </figure>
                    <div class="text-base font-medium mr-2">
                        {{ this.userData.name | titlecase  }} {{ this.userData.lastname | titlecase }}
                    </div>
                    <div class="text-xl font-medium">
                        <mat-icon
                            [inline]="true"
                            *ngIf="!showOpen; else iconCloseMenu"
                        >
                            keyboard_arrow_up
                        </mat-icon>
                        <ng-template #iconCloseMenu>
                            <mat-icon
                                [inline]="true"
                            >
                                keyboard_arrow_down
                            </mat-icon>
                        </ng-template>
                    </div>
                </div>
                
                <mat-menu #menu="matMenu" class="header__content-menu" >
                    <button mat-menu-item (click)="logo()">Inicio</button>
                    <button mat-menu-item (click)="myProfile()">Perfil</button>
                    <button mat-menu-item (click)="myPost()">Mis publicaciones</button>
                    <button mat-menu-item (click)="goToPost()" >Publicar inmueble</button>
                    <button mat-menu-item (click)="signOut()">Cerrar sesión</button>
                    <hr class="header__line"/>
                    <div class="flex justify-center items-center p-5">
                        <div class="mr-2">
                            <img src="../../../../assets/img/flag-icon.png" alt="">
                        </div>
                        <div class="mr-2">
                            <a href="#">
                                <img src="../../../../assets/img/facebook-icon.png" alt="" >
                            </a>
                        </div>
                        <div>
                            <a href="">
                                <img src="../../../../assets/img/instagram-icon.png" alt="" >
                            </a>
                        </div>
                    </div>
                    
                </mat-menu>
            </div>
        </section>
    </section>
    <section class="lg:hidden">
        <ul class="divide-y-2 divide-solid" *ngIf="isLogged">
            <li>
                <div class="p-3 grid grid-cols-3 grid-rows-1 gap-4" (click)="logo()">
                    <div class="col-spam-1 flex justify-start" >
                        <div class="lg:hidden absolute text-4xl	ml-2 mt-2 header__icon-menu" (click)="showMenuResponse()">
                            <mat-icon
                                [inline]="true"
                                *ngIf="!menuResponse"
                                class="ro-font-purple"
                            >
                                menu
                            </mat-icon>
                            <mat-icon
                                [inline]="true"
                                *ngIf="menuResponse"
                                class="ro-font-purple"
                            >
                                close
                            </mat-icon>
                        </div>
                    </div>
                    <div class="col-spam-1 flex justify-center" >
                        <div class="header__logo-icon text-3xl ro-bg-purple ro-font-white grid place-content-center ">
                            <div>R</div>
                        </div>
                    </div>
                    <div class="col-spam-1 flex justify-end item-center flex-row">
                        <div class="flex item-center pt-1 mr-1">
                            <img 
                                class="header__imgUser" 
                                [src]='loadPhotoProfile()' 
                                alt="" 
                            >
                        </div>
                    </div>
                </div>
                
            </li>
            <li class="p-3 flex justify-center text-lg font-light hover:bg-sky-700" (click)="myProfile()" *ngIf="menuResponse">
                Mi Perfil
            </li>
            <li class="p-3 flex justify-center text-lg font-light hover:bg-sky-700" (click)="myPost()" *ngIf="menuResponse">
                Mis publicaciones
            </li>
            <li class="p-3 flex justify-center text-lg font-light" routerLink="/post" *ngIf="menuResponse">
                Publicar inmueble
            </li>
             <li class="p-3 flex justify-center text-lg font-light" (click)="signOut()" *ngIf="menuResponse">
                Cerrar sesión
             </li>
        </ul>
        <ul class="divide-y-2 divide-solid" *ngIf="!isLogged">
            <li class="p-3 flex justify-between d-full text-lg font-light">
                <div class="col-spam-1 flex justify-start" >
                    <div class="lg:hidden absolute text-4xl	ml-2 mt-2 header__icon-menu" (click)="showMenuResponse()">
                        <mat-icon
                            [inline]="true"
                            *ngIf="!menuResponse"
                            class="ro-font-purple"
                        >
                            menu
                        </mat-icon>
                        <mat-icon
                            [inline]="true"
                            *ngIf="menuResponse"
                            class="ro-font-purple"
                        >
                            close
                        </mat-icon>
                    </div>
                </div>
                <div class="col-start-2 flex justify-center" (click)="logo()">
                    <div class="header__logo-icon text-3xl ro-bg-purple ro-font-white grid place-content-center ">
                        <div>R</div>
                    </div>
                </div>
            </li>
            <li class="p-3 flex justify-center text-lg font-light" *ngIf="menuResponse">
                <div class="col-start-3 flex justify-end">
                    <ro-button-flat
                        color = 'primary'
                        routerLink="/auth"
                    >   
                        <div class="text-lg p-2">
                            Inciar sesión
                        </div>
                    </ro-button-flat>
                </div>
            </li>
            <li class="p-3 flex justify-center text-lg font-light" *ngIf="menuResponse">
                <div class="col-start-3 flex justify-end">
                    <ro-button-flat
                        color = 'accent'
                        routerLink="/auth/register"
                    >   
                        <div class="text-lg p-2">
                            Registrarse
                        </div>
                    </ro-button-flat>
                </div>
            </li>
            
        </ul>
    </section>
</section>



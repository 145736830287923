import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry, map, concatMap, finalize} from 'rxjs/operators';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, from, of, EMPTY } from 'rxjs';
import { Account } from '../interfaces';
import { ActivatedRoute, Router } from '@angular/router';

const baseUrl = `${environment.uri}/accounts`;
const uri = `${environment.uri}`;

declare const FB: any;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accountSubject: BehaviorSubject<Account>;
  public account: Observable<Account>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) { 
    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();
  }

  setSesionStorage(keyname: string, value: string ): void{
    sessionStorage.setItem(keyname, value)
  }
  getSesionStorage( keyname: string ): string{
    return sessionStorage.getItem(keyname);
  }
  removeSesionStorage( keyname: string ): void{
    sessionStorage.removeItem(keyname);
  }

  public registerFB(body) : Observable<any> {
    return this.http.post<any>(environment.uri + `/registerandLoginfb`, body)
    .pipe(
      map( res => res 
      )
    );
  };

  registerUser(body) {
    return this.http.post<any>(`${uri}/register`, body)
    .pipe(
      map( response => response )
    );
  }

  login(body){
    return this.http.post<any>(`${uri}/login`, body)
    .pipe(
      map( response => response )
    );
  }

  sendEmailRecoverPassword(body){
    return this.http.post<any>(`${uri}/recoverPassword`, body)
    .pipe(
      map( response => response )
    );
  }

  validateRecoverPassword(body){
    return this.http.post<any>(`${uri}/validateRecoverPassword`, body)
    .pipe(
      map( response => response )
    );
  }

}

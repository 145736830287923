import {ModuleWithProviders}  from '@angular/core';
import { StoreModule, ActionReducerMap,} from '@ngrx/store';
import { 
    HomeAction, 
    HomeEffects, 
    HomeReducer, 
    HomeState
} from './modules/home/store';
import { 
    ProfileAction, 
    ProfileEffects, 
    ProfileReducer, 
    ProfileState
} from './modules/profile/store';

interface AppState {
    home: HomeState.IState,
    profile: ProfileState.IState
}

export const AppEffects: any[] = [HomeEffects, ProfileEffects];

export const AppReducerModule: ActionReducerMap<AppState> = {
  home: HomeReducer,
  profile: ProfileReducer
}

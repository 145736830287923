import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { HomeAction } from './home.actions';
import { PropertyService } from '@app/shared/services/property.service';

@Injectable()
export class HomeEffects {
    constructor(
        private actions$: Actions, 
        private propertyService : PropertyService
    ) {}
    
    loguin$ = createEffect(
        () => 
            this.actions$.pipe(
                ofType(HomeAction.Types.LOGUIN),
                switchMap(() => this.propertyService.getMe().pipe(
                    map( resp => new HomeAction.LoguinRequest(
                        {
                            email: resp.email,
                            name: resp.name,
                            lastname: resp.lastname,
                            type: resp.rol,
                            img: resp.urlimage,
                            creationprofile: resp.creationprofile
                        }
                    ))
                ))
            )
        
    );

}
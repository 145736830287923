import { Action } from "@ngrx/store";

export namespace ProfileAction {
    export const enum Types {
         LOGUIN = "Login [profile]",
         LOGUIN_REQUEST = "Loguin Request [profile]",
         LOGUIN_SUCCESS= "Loguin Success [profile]",
    }

    export class Loguin implements Action{
        readonly type = Types.LOGUIN;
        payload: string = null;
    }

    export class LoguinRequest implements Action{
        readonly type = Types.LOGUIN_REQUEST;
        constructor(public payload: any) {}
    }

    export class LoguinSuccess implements Action{
        readonly type = Types.LOGUIN_SUCCESS;
        payload: string = null;
    }

    export type Actions = Loguin | LoguinRequest | LoguinSuccess;
}
<button
    mat-flat-button
    isRoundButton="true"
    class="button"
    [type]="type"
    [class]="class" 
    [ngStyle]="ngStyle"
    [color]="color"
    [disabled]="disabled"
>
    <ng-content></ng-content>
</button>
        
<button
    mat-raised-button
    [type]="type"
    class="button"
    [ngStyle]="ngStyle"
    [color]="color"
    [disabled]="disabled"
>
    <ng-content></ng-content>
</button>

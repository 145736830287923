import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProfileAction } from './profile.actions';
import { PropertyService } from '@app/shared/services/property.service';

@Injectable()
export class ProfileEffects {
    constructor(
        private actions$: Actions, 
        private propertyService : PropertyService
    ) {}
    
    loguin$ = createEffect(
        () => 
            this.actions$.pipe(
                ofType(ProfileAction.Types.LOGUIN),
                switchMap(() => this.propertyService.getMe().pipe(
                    map( resp => new ProfileAction.LoguinRequest({
                        email: resp.email,
                        name: resp.name,
                        lastname: resp.lastname,
                        rol: resp.rol,
                        image: resp.image,
                        age: resp.age,
                        description: resp.description,
                        ocupation: resp.ocupation,
                        idocupation: resp.idocupation,
                        code: resp.code,
                    }))
                ))
            )
        
    );

}

<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label  *ngIf="label">{{ label }}</mat-label>
    <input matInput 
        [type]="type" 
        [placeholder]="placeholder" 
        [formControlName]="controlName" 
        [maxlength]="maxlength" 
        [minlength]="minlength"
        [ngClass]="ngClass"
    >
</mat-form-field>

  

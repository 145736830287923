import { ProfileAction } from './profile.actions';
import { ProfileModal } from './profile.model';
import { ProfileState } from './profile.state';

export function ProfileReducer(
    state = ProfileState.initialState,
    action: ProfileAction.Actions
): ProfileModal{
    switch (action.type) {
        case ProfileAction.Types.LOGUIN_SUCCESS: {
            return {
                ...state
            }
        }

        case ProfileAction.Types.LOGUIN_REQUEST :{
            const data = action.payload;
            return {
                ...state,
                data,
            }
        }
        default: {
            return state;
        }
    }
}
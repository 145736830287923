<!-- <mat-slide-toggle
    class="example-margin"
    [color]="'primary'"
    [disabled]="false"
    [formControlName]="controlName"
    [labelPosition]="position">
    <ng-content></ng-content>
</mat-slide-toggle> -->
<mat-checkbox 
    [formControlName]="controlName"
    [checked]="allComplete"
    [color]="'primary'"
    (ngModelChange)="updateAllComplete()">
    <ng-content></ng-content>
</mat-checkbox>
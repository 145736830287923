import {Component, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DialogData } from '@app/modules/profile/modales/editProfile/editProfile.modal';

@Component({
  templateUrl: './modal.confirmation.component.html',
  styleUrls: ['./modal.confirmation.component.scss'],
})
export class modalConfirmationComponent implements OnInit {
    @Output() cancelEvent = new EventEmitter<boolean>();
    @Output() acceptEvent = new EventEmitter<boolean>();
    textIcon:any;;

    constructor(
      public dialogRef: MatDialogRef<modalConfirmationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    // @Input() controlName:string;
    // @Input() position:string = 'after';
    
    ngOnInit() {
      // const textIcon : any = this.listTextIcon.find(item => item.name === this.data.type);
      // this.textIcon = textIcon;
    }

    cancel(): void {
      this.cancelEvent.emit()
      this.dialogRef.close();
    }

    on(): void {
      this.acceptEvent.emit()
      this.dialogRef.close();
    }
}


<section class="footer">
    <section class="flex justify-center pt-5 pb-3 container mx-auto">
        <div class="flex flex-col w-full">
            <section class="footer__container text-base pb-5">
                <div class="footer__item-title px-5 pb-2">
                    <h2 class="footer__title text-lg">
                        Room out
                    </h2>
                    <h3 class="footer__subtitle fsize-20">
                        Modalidades
                    </h3>
                </div>
                <div class="footer__item-1 px-5">
                    <ul class="footer__column text-sm">
                        <li class="footer__cell">
                            <a [href]="uri +'/main;typeByproperty=1;region=;district='" class="underline  underline-offset-8">Roomates</a>
                        </li>
                        <li class="footer__cell">
                            <a [href]="uri + '/main;typeByproperty=3;region=;district='" class="underline  underline-offset-8">Departamentos</a>
                        </li>
                        <li class="footer__cell">
                            <a [href]="uri + '/main;typeByproperty=4;region=;district='" class="underline  underline-offset-8">Mini departamentos</a>
                            
                        </li>
                        <li class="footer__cell">
                            <a [href]="uri + '/main;typeByproperty=5;region=;district='" class="underline  underline-offset-8">Casas de playa - campo</a>
                            
                        </li>
                    </ul>
                </div>
                <div class="footer__item-2 px-5">
                    <ul class="footer__column text-sm">
                        <li class="footer__cell">
                            <a [href]="uri + '/main;typeByproperty=2;region=;district='" class="underline  underline-offset-8">Servicio plus independientes</a>
                        </li>
                        <li class="footer__cell">
                            <a [href]="uri + '/post'" class="underline  underline-offset-8">Publica tu espacio</a>
                        </li>
                        <li class="footer__cell">
                            Encontramos tu depa ideal
                        </li>
                    </ul>
                </div>
                <div class="footer__item-3 px-5">
                    <ul class="footer__column">
                        <li class="footer__cell footer__title-social fsize-15">
                            Siguenos
                        </li>
                        <li class="footer__cell">
                            <div class="flex flex-row">
                                <a  href="https://www.facebook.com/people/ROOM-OUT/100075794112967/" class="footer_container-icon" target="_blank">
                                    <img src="../../../../assets//img//facebook.png" alt="" width="30">                            
                                </a>
                                <a  href="https://www.instagram.com/roomoutperu/" class="footer_container-icon" target="_blank">
                                    <img src="../../../../assets//img//instagram.png" alt="" width="30">
                                </a>
                                <a  href="https://api.whatsapp.com/send?phone=51928067019" class="footer_container-icon" target="_blank">
                                    <img src="../../../../assets//img//whatsApp.png" alt="" width="30">
                                </a>
                                <a  href="https://www.tiktok.com/@room_out" class="footer_container-icon" target="_blank">
                                    <img src="../../../../assets//img//tik-tok.png" alt="" width="30">
                                </a>
                            </div>
                        </li>
                        <li class="footer__cell footer__title-social text-base">
                            Número de contacto
                        </li>
                        <li class="footer__cell">
                            <div class="flex flex-row align-items-center">
                                <div class="pr-2">
                                    <a href="tel:+51928067019">
                                        <img src="../../../../assets//img//call.png" alt="" width="30">
                                    </a>                            
                                </div>
                                <div class="px-2 footer__phone fsize-20">
                                    +51 928 067 019
                                </div>
                            </div>
                        </li>
                    </ul> 
                </div>
            </section>
            <section class="flex justify-center flex-wrap text-sm">
                <div class="text-center text-sm mr-1">
                    ©Copyright 2024 room-out.com
                </div>
                <div class="text-center">
                    <a class="footer__terms" href="https://docs.google.com/file/d/1lsqDeR3fGAloBzrYmTu8RVo3ZCMsvpP6/edit?filetype=msword" target="_blank">Términos y condiciones de Uso - Términos y condiciones contratación</a> - 
                    <span><a class="footer__terms" href="https://docs.google.com/document/d/10Da_pb7b0mVGuVlsI_n7enMWD1LIFFIz-B4M40aN5FM/edit" target="_blank">Política de privacidad</a></span>
                </div>
            </section>
        </div>
    </section>
</section>
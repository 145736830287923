import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyService } from '@app/shared/services/property.service';
import { profileData } from '@app/modules/profile/store';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { AuthService } from '@app/shared/services/auth.service';
import { URI_S3, BUCCKET_PROFILE, IMAGE_DEFAULT, ROUTES, SESSION_STORAGE } from './header.constants';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private componentDestroyed$ = new Subject();
  shhowMenu: boolean = false;
  userData: any = undefined;
  isLogged: boolean;
  user: any;
  loggedIn: any;
  menuResponse: boolean = false;
  showOpen = false;
  constructor(
    public router: Router,
    public propertyService: PropertyService,
    public store: Store,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isLogged = this.authService.getSesionStorage(SESSION_STORAGE.token) !== null;
    if (this.isLogged) {
      this.loadData();
    }
  }

  private loadData() {
    this.store
      .pipe(
        select(profileData),
        filter(val => val !== undefined),
        takeUntil(this.componentDestroyed$)
      ).subscribe(resp => {
        this.userData = resp.data;
      }
      );
  }


  signOut(): void {
    this.authService.removeSesionStorage(SESSION_STORAGE.token);
    this.authService.removeSesionStorage(SESSION_STORAGE.user);
    window.location.reload();


  }

  public logo() {
    this.router.navigateByUrl(ROUTES.raiz);
  }

  public myProfile() {
    this.router.navigateByUrl(ROUTES.profile);
    this.showMenuResponse();
  }
  myPost() {
    this.router.navigateByUrl(ROUTES.myPost);
    this.showMenuResponse();
  }

  public goToPost() {
    this.router.navigateByUrl(ROUTES.post);
    this.showMenuResponse();
  }

  public mouseover() {
    this.shhowMenu = true;
  }

  public mouseout() {
    this.shhowMenu = false;
  }

  public showMenuResponse() {
    this.menuResponse = !this.menuResponse;
  }

  loadPhotoProfile() {
    if (this.userData && this.userData.image) {
      return URI_S3 + BUCCKET_PROFILE + this.userData.image.code + '.' + this.userData.image.mimetype;
    } else {
      return IMAGE_DEFAULT;
    }
  }

  openMenu(){
    this.showOpen = true;
  }
  closeMenu(){
    this.showOpen = false;
  }

  ngOnDestroy() {
    this.componentDestroyed$.unsubscribe();
  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DialogData } from '@app/modules/profile/modales/editProfile/editProfile.modal';

@Component({
  templateUrl: './modal.error.component.html',
  styleUrls: ['./modal.error.component.scss'],
})
export class modalErrorComponent implements OnInit {
    listTextIcon = [
      {
        key: 'error_outline',
        title: 'Error',
        color: 'ro-font-red',
        name: 'error'
      },
      {
        key: 'info_outline',
        title: 'Info',
        color: 'ro-font-yellow',
        name: 'info'
      },
      {
        key: 'check_circle_outline',
        title: 'Exitoso',
        color: 'ro-font-green',
        name: 'success'
      }
    ]

    textIcon:any;;

    constructor(
      public dialogRef: MatDialogRef<modalErrorComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    // @Input() controlName:string;
    // @Input() position:string = 'after';
    
    ngOnInit() {
      const textIcon : any = this.listTextIcon.find(item => item.name === this.data.type);
      this.textIcon = textIcon;
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

}
